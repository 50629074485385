<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   id="Layer_1"
   viewBox="0 0 100 100"
   version="1.1"
   sodipodi:docname="prod.svg"
   inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)">
  <metadata
     id="metadata15">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <defs
     id="defs13" />
  <sodipodi:namedview
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1"
     objecttolerance="10"
     gridtolerance="10"
     guidetolerance="10"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:window-width="1539"
     inkscape:window-height="750"
     id="namedview11"
     showgrid="false"
     inkscape:zoom="6.052834"
     inkscape:cx="27.868033"
     inkscape:cy="52.220696"
     inkscape:window-x="0"
     inkscape:window-y="45"
     inkscape:window-maximized="0"
     inkscape:current-layer="layer1"
     inkscape:document-rotation="0" />
  <style
     id="style2">.st1{fill:#999}</style>
  <path
     class="st1"
     d="M100 100H0V0h100v100zM9.7 90h80.7V10H9.7"
     id="path6"
     style="fill:#ffcc00" />
  <g
     inkscape:groupmode="layer"
     id="layer1"
     inkscape:label="Layer 1"
     style="display:inline">
    <g
       id="g18-5"
       transform="matrix(1.6041687,0,0,1.6041687,-12.642788,-23.551135)"
       style="fill:#ffcc00">
      <g
         id="g21-3"
         style="fill:#ffcc00">
        <path
           class="st1"
           d="m 52.5,53.7 3.7,1 2.1,-8 -3.6,-1 c 0,-1.3 -0.2,-2.6 -0.5,-3.9 l 3.3,-1.9 -4.1,-7.2 -3.4,2 C 49.1,33.8 48,33 46.9,32.3 l 1,-3.7 -8,-2.1 -1,3.7 c -1.3,0 -2.6,0.2 -3.9,0.5 l -1.9,-3.3 -7.1,4.2 1.9,3.3 c -0.9,0.9 -1.7,2 -2.4,3.1 l -3.6,-1 -2.1,8 3.6,1 c 0,1.3 0.2,2.6 0.5,3.9 l -3.3,1.9 4.1,7.2 3.3,-1.9 c 0.9,0.9 2,1.7 3.1,2.4 l -1,3.6 8,2.1 1,-3.7 c 1.3,0 2.6,-0.2 3.9,-0.5 l 1.9,3.3 7.2,-4.1 -1.9,-3.3 c 0.9,-1 1.7,-2.1 2.3,-3.2 z M 32.7,49.5 c -2,-3.5 -0.8,-8 2.7,-10.1 3.5,-2 8,-0.8 10.1,2.7 2,3.5 0.8,8 -2.7,10.1 -3.6,2 -8.1,0.8 -10.1,-2.7 z"
           id="path8-5"
           style="fill:#ffcc00"
           sodipodi:nodetypes="cccccccccccccccccccccccccccccccccscccs" />
      </g>
    </g>
  </g>
</svg>
