import './projects.css';

// import "../projects/general";
// import "../projects/toolbar";

import App from '../projects/projects.svelte';

document.addEventListener('DOMContentLoaded', () => {
  const app = new App({
      target: document.querySelector("div#main-container")
  });
});
