<script>
  import Prodsvg from './prodsvg.svelte';

  import { Uploader } from '../shared/uploader';
  import { Message } from '../shared/modular_messages';
  import { setFocus } from '../shared/utils';

  export let experiment;
  export let type;
  export let hovered;
  export let renaming;

  let isPublic = true;
  let stage = experiment.stage;
  let name = experiment.name;
  let link = '';
  if (!renaming && type == 'personal')
    link = `/experiments/${experiment.slug}/edit/`;
  else if (!renaming)
    link = `/experiments/new?from=${experiment.slug}`



  function tryToRename() {
    return new Promise(r=>{
      console.log("name",name,"experiment.name",experiment.name);
      if (name == experiment.name) {
        renaming = false;
        r(true);
      }
      else if (window.$(`.projects.private li a[data-name='${name}']:not([data-slug='${experiment.slug}'])`).length){
          Message.error(`There already is another experiment named <strong>${name}</strong>`);
          r(false);
      }
      else
          Uploader.request_json({rename: name}, '/experiments/'+experiment.slug)
          .success( m => {
              name = m.newname;
              experiment.name = m.newname;
              Message.log(`Experiment ${name} successfully renamed <strong>${m.newname}</strong>` , 'experimentStatus' );
              r(true);
          })
          .error( m => {
              Message.log(`There was a problem renaming the experiment: ${m.error||m.message}`) 
              r(false);
          });
    })
  };
  async function edit_keyup(e){
    name = e.target.innerText;
    if (e.key=="Enter"){
      hovered = false;
      let success = await tryToRename();
      if (success) renaming = false;
      else{
        name = experiment.name;
        hovered = true;
      }
    }
    else if (e.key=="Escape"){
      name = experiment.name;
      renaming = false;
    }
  }

</script>

{#if type == 'personal'}
  <span class='statuses'>
    <!-- {#if isPublic}
        <span class='public' help='Other users CAN clone this project'></span>
    {:else}
        <span class='private' help='Other users CANNOT clone this project'></span>
    {/if} -->
    {#if stage=='prod'}
      <span class='prod' help='You published this project for data collection'><Prodsvg /></span>
    {:else}
      <span class='dev' help='This project is currently NOT collecting data'></span>
    {/if}
  </span>
{/if}

  {#if renaming && hovered}
    <a
      href={link}
      help='<strong>{name}</strong><br>{experiment.description||'No description'}'
      data-name={name}
      data-cached_size={experiment.cached_size}
      data-updated_at={experiment.updated_at}
      data-slug={experiment.slug}
      contenteditable=""
      on:keyup={e=>edit_keyup(e)}
      on:blur={e=>renaming=false}
      use:setFocus
    >
      {name}
    </a>
  {:else}
    <a 
      href={link}
      help='<strong>{name}</strong><br>{experiment.description||'No description'}'
      data-name={name}
      data-cached_size={experiment.cached_size}
      data-updated_at={experiment.updated_at}
      data-slug={experiment.slug}
    >
      {name}
    </a>
    {#if type == 'personal'}
      <span class="details">
        {(new Date(experiment.updated_at)).toLocaleString()}; {Math.round(experiment.cached_size/1000000)}MB
      </span>
    {/if}
  {/if}
{#if type == 'personal'}
  <!-- Need to compute size locally -->
  <!-- <span class='size'>{Math.round(experiment.cached_size/10000)/100}</span> -->
{/if}
