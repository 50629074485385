import { User } from '../shared/user';

const relation = (a,b,attr,order) => {
    let test;
    if (order=="up") test = ($(a).find('a').data(attr) > $(b).find('a').data(attr));
    else test = $(a).find('a').data(attr) < $(b).find('a').data(attr);
    return 2*Number(test==true) - 1;
}
const sort_projects = (by,order) => {
    console.log("sort projects", by, order);
    let sorted;
    switch (by){
        case 'name':
            sorted = $(".private li").sort( (a,b) => relation(a,b,'name',order) );
        break;
        case 'lastedit':
            sorted = $(".private li").sort( (a,b) => relation(a,b,'updatedAt',order) );
        break;
        case 'size':
            sorted = $(".private li").sort( (a,b) => relation(a,b,'size',order) );
        break;
    }
    $(".private li").detach();
    $(".private ul").append(sorted);
}


document.addEventListener('DOMContentLoaded',()=>{
    console.log("User:", window.User);
    // User.onlogin( ()=> { console.log("reloading"); window.location.reload() } );
    // User.onlogout( ()=> { console.log("logout"); window.location.reload() } );

    $(".sorts span").click( function(){
        if ($(this).hasClass("down")) $(this).removeClass("down").addClass("up");
        else if ($(this).hasClass("up")) $(this).removeClass("up").addClass("down");
        else {
            $(".sorts .up, .sorts .down").removeClass("up").removeClass("down");
            $(this).addClass("down");
        }
        sort_projects(...this.className.split(" "));
    } );

    $(".filter").on("input", e=>{
        $(e.target).addClass("editable");
        const text = e.target.innerText.replace(/[\n\r]/g,'');
        if (text.length==0 || !text.match(/\S/)){
            $(e.target).removeClass("editable");
            $(".projects.private li").show();
        }
        else
            $(".projects.private li a").each( function(){
                if (this.text.indexOf(text)<0)
                    $(this).parents("li").hide();
                else
                    $(this).parents("li").show();
            });
    }).focus( e=> $(e.target).addClass("editable") ).keydown(e=>{
        if (e.key=="Escape"){
            $(".projects.private li").show();
            $(e.target).removeClass("editable").text("");
        }
        else if (e.key=="Enter") return false;
    }).blur(e=>{
        const text = e.target.innerText.replace(/[\n\r]/g,'');
        if (text.length==0 || !text.match(/\S/)){
            $(e.target).removeClass("editable");
            $(".projects.private li").show();
        }
    });
});
